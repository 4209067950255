<template>
  <div>
    <v-alert type="success">
      New service request are initiated from the customer dashboard. Use quick
      find to locate the desired customer or
      <router-link :to="`/customers`">
        <span>view all customers</span>
      </router-link>
    </v-alert>

    <v-row>
      <v-col
        cols="12"
        md="2"
        v-if="
          permissions &&
            permissions.serviceRequest &&
            permissions.serviceRequest.canFilterByBranch
        "
      >
        <v-select
          v-model="viewByBranch"
          :items="viewByBranchItems"
          item-text="name"
          item-value="value"
          label="Choose Branch..."
          :loading="loadingCustomers"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        md="2"
        v-if="
          permissions &&
            permissions.serviceRequest &&
            permissions.serviceRequest.canFilterByAccountManager
        "
      >
        <v-select
          v-model="viewByAccountManager"
          :items="viewByAccountManagerItems"
          item-text="name"
          item-value="value"
          label="Choose Account Manager..."
          :loading="loadingCustomers"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        md="2"
        v-if="
          permissions &&
            permissions.serviceRequest &&
            permissions.serviceRequest.canFilterByCustomer
        "
      >
        <v-select
          v-model="viewByCustomer"
          :items="viewByCustomerItems"
          item-text="name"
          item-value="value"
          label="Choose Customer..."
          :loading="loadingCustomers"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" v-if="isCrewLeaderOrAccountManager">
        <v-select
          v-model="viewByAssignedCrewLeader"
          :items="viewByAssignedCrewLeaderItems"
          item-text="name"
          item-value="value"
          label="View by Assigned Crew Leader..."
          :loading="loadingCrewLeaders"
        ></v-select>
      </v-col>
      <!-- <v-col cols="12" md="2">
        <v-select
          v-model="viewByPriority"
          :items="viewByPriorityItems"
          item-text="name"
          item-value="value"
          label="Choose Priority..."
        ></v-select>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <a
          href="#"
          :class="{
            'active-request-type-link': requestTypeToShow == 'essential'
          }"
          @click.prevent="requestTypeToShow = 'essential'"
        >
          Requests ({{ requestsCount }})</a
        >
        <a
          href="#"
          class="ml-4"
          :class="{
            'active-request-type-link':
              requestTypeToShow == 'Request For Proposal'
          }"
          @click.prevent="requestTypeToShow = 'Request For Proposal'"
          >Request For Proposal ({{ requestForProposalCount }})</a
        >
        <a
          href="#"
          class="ml-4"
          :class="{
            'active-request-type-link': requestTypeToShow == 'Wildlife Damage'
          }"
          @click.prevent="requestTypeToShow = 'Wildlife Damage'"
          >Wildlife Damage ({{ wildlifeDamageCount }})</a
        >
        <a
          href="#"
          class="ml-4"
          :class="{
            'active-request-type-link': requestTypeToShow == 'Mulch Request'
          }"
          @click.prevent="requestTypeToShow = 'Mulch Request'"
          >Mulch Request ({{ mulchRequestCount }})</a
        >
      </v-col>
    </v-row>

    <v-tabs v-model="tab" background-color="transparent" centered grow>
      <v-tab href="#tab-1"> New ({{ newCount }}) </v-tab>

      <v-tab href="#tab-2"> Due This Week ({{ dueCount }}) </v-tab>

      <v-tab href="#tab-3"> All Open ({{ openCount }}) </v-tab>

      <v-tab href="#tab-4"> In Process ({{ inProcessCount }}) </v-tab>
      <v-tab href="#tab-5"> On Hold ({{ onHoldCount }}) </v-tab>

      <v-tab href="#tab-6"> Closed ({{ closedCount }}) </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="i in 6" :key="i" :value="'tab-' + i">
        <v-container fluid>
          <v-row>
            <v-col>
              <v-btn
                class="orange-bg white--text mt-4"
                @click="getExportFile"
                small
              >
                Export
              </v-btn>
              <v-btn
                :disabled="selectedRequests.length == 0"
                class="orange-bg white--text mt-4 ml-2"
                @click="printRequests(selectedRequests.map(i => i.id))"
                small
              >
                Print Selected ({{ selectedRequests.length }})
              </v-btn>
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                label="Search"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                hide-details
                single-line
                class="mb-2"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="records"
            :search="search"
            item-key="id"
            :loading="isLoading"
            :items-per-page="50"
            :footer-props="{
              'items-per-page-options': [50, 100, 150, 200, -1]
            }"
            :options.sync="options"
            loading-text="Loading... Please wait"
            class="elevation-1 request-table"
            select-strategy="all"
            show-select
            item-value="id"
            v-model="selectedRequests"
          >
            <template #[`item.requestTypeName`]="{ item }">
              <router-link
                :to="`/service-requests/${item.id}/update`"
                class="blue-color"
              >
                {{ getServiceRequestTypeName(item.requestType) }}
              </router-link>
            </template>
            <template #[`item.comments`]="{ item }">
              <span>{{ item.comments }} </span>
            </template>
            <template #[`item.streetLocation`]="{ item }">
              <span>{{ item.location }} </span>
            </template>
            <template #[`item.dateCreated`]="{ item }">
              <span
                :style="
                  new Date(item.targetCompletionDate) < new Date()
                    ? 'color: red; font-weight: bold;'
                    : ''
                "
                >{{ formatDateUs(item.dateCreated) }}
              </span>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                class="ma-2"
                text
                icon
                @click.stop="item.deleteDialog = true"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-dialog v-model="item.deleteDialog" persistent max-width="290">
                <v-card>
                  <v-card-title class="headline">
                    Delete Request
                  </v-card-title>
                  <v-card-text
                    >Are you sure you want to delete request:
                    <p>{{ item.title }}</p></v-card-text
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="item.deleteDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="deleteRequest(item)"
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import { GET_PAGED, POST_DATA, API_USERS } from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  // GET_QUERY,
  GET_PAGED,
  DELETE,
  API_SERVICE_REQUESTS,
  API_CUSTOMERS,
  API_EMPLOYEES,
  POST_DATA
} from "@/core/store/crud.module";
import requestsPrinter from "@/core/helpers/printRequests";
import dateFormatter from "@/core/helpers/dateFormatter";
import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";
import Mgr from "@/core/services/security.service";

export default {
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    isLoading: false,
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ["dateCreated"],
      sortDesc: [true]
    },
    search: "",
    headers: [
      {
        text: "Date Created",
        align: "start",
        value: "dateCreated",
        class: "orange-bg"
      },
      { text: "Issue #", value: "id", class: "orange-bg" },
      {
        text: "Service Type",
        value: "requestTypeName",
        class: "orange-bg"
      },
      { text: "Community", value: "communityName", class: "orange-bg" },
      {
        text: "Property Address",
        value: "streetLocation",
        class: "orange-bg"
      },
      {
        text: "Description",
        value: "description",
        class: "orange-bg"
      },
      { text: "Assigned To", value: "assignedToFullName", class: "orange-bg" },
      // { text: "Priority", value: "priorityId", class: "orange-bg" },
      {
        text: "Comments",
        value: "comments",
        class: "orange-bg",
        sortable: false
      },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    selectedRequests: [],
    records: [],
    totalRecords: null,
    // userQuery: "",
    viewByBranch: "all",
    viewByBranchItems: [{ name: "View All Branches", value: "all" }],

    viewByAccountManager: "all",
    viewByAccountManagerItems: [
      { name: "View All Account Managers", value: "all" }
    ],

    viewByCustomer: "all",
    viewByCustomerItems: [{ name: "View All Customers", value: "all" }],

    viewByAssignedCrewLeader: "me",
    viewByAssignedCrewLeaderItems: [{ name: "View mine", value: "me" }],
    loadingCrewLeaders: false,

    // viewByPriority: "all",
    // viewByPriorityItems: [
    //   { name: "View All Priorities", value: "all" },
    //   { name: "Low", value: "Low" },
    //   { name: "Normal", value: "Normal" },
    //   { name: "High", value: "High" }
    // ],
    tab: null,
    loadingCustomers: false,
    permissions: {},
    newCount: 0,
    openCount: 0,
    dueCount: 0,
    inProcessCount: 0,
    onHoldCount: 0,
    closedCount: 0,
    // proposals = Request For Proposal
    // wildlife = Wildlife Damage
    // mulch = Mulch Request
    requestTypeToShow: "essential", // possible types here: essential, Request For Proposal, Wildlife Damage, Mulch Request
    requestsCount: 0,
    requestForProposalCount: 0,
    wildlifeDamageCount: 0,
    mulchRequestCount: 0
  }),
  watch: {
    viewByBranch: async function() {
      await this.getRequests();
    },
    viewByAccountManager: async function() {
      await this.getRequests();
    },
    viewByCustomer: async function() {
      await this.getRequests();
    },
    viewByAssignedCrewLeader: async function() {
      await this.getRequests();
    },

    // "options.sortBy": async function() {
    //   await this.getRequests();
    //   // if (this.userQuery && this.userQuery.length > 0) {
    //   //   await this.getUsers();
    //   // } else {
    //   //   this.users = [];
    //   // }
    // },
    tab: async function() {
      await this.getRequests();
    },
    requestTypeToShow: async function() {
      await this.getRequests();
    }
  },
  async created() {
    await permissionsHelper.getPermissions().then(this.getPermissions);
    this.userInfo = await this.mgr.getUser();
    // .then(userInfo => {
    //   this.userInfo = userInfo;
    // });
    if (this.isCrewLeaderOrAccountManager) {
      await this.getRecipients();
    }
  },
  async mounted() {
    await this.getCustomers();

    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Service Requests" }]);
    await this.getRequests();
    // await this.getExportFile();
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    getServiceRequestTypeName(requestType) {
      if (requestType) return requestType.name;
      return "";
    },
    async printRequests(requestIds) {
      let url = `${API_SERVICE_REQUESTS}/GetByIds`;

      let requestsResponse = await this.$store.dispatch(POST_DATA, {
        listName: url,
        data: requestIds
      });

      let requestsToPrint = requestsResponse.data.$values;
      await requestsPrinter.printRequests(requestsToPrint);
    },
    async getRecipients() {
      // let comunityItems = dataModels.communityDetails;
      let self = this;

      await this.$store
        .dispatch(GET_PAGED, {
          listName: API_EMPLOYEES,
          params: {
            role: "Crew Leader",
            branch: self.userInfo.branches.$values.join(),
            includeHasAllBranchesAccess: false
          }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let crewLeaders = response.data;

            crewLeaders.forEach(el => {
              self.viewByAssignedCrewLeaderItems.push({
                name: el.fullName,
                value: el.aspireId
              });
            });
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    async getCustomers() {
      this.loadingCustomers = true;
      let self = this;
      await this.$store
        .dispatch(GET_PAGED, {
          listName: API_CUSTOMERS,
          params: { mediumFormat: true }
          // ,
          // params: {
          //   // query: this.userQuery,
          //   // skip: this.options.itemsPerPage * (this.options.page - 1),
          //   // take:
          //   //   this.options.itemsPerPage === -1
          //   //     ? this.totalUsers
          //   //     : this.options.itemsPerPage,
          //   // sort: this.sort
          // }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let items = response.data.$values;

            self.viewByBranchItems = [
              { name: "View All Branches", value: "all" }
            ];
            self.viewByAccountManagerItems = [
              { name: "View All Account Managers", value: "all" }
            ];
            self.viewByCustomerItems = [
              { name: "View All Customers", value: "all" }
            ];

            items.forEach(el => {
              self.viewByBranchItems.push({
                name: el.branchName,
                value: el.branchName
              });

              self.viewByAccountManagerItems.push({
                name: el.accountManagerName,
                value: el.accountManagerName
              });

              self.viewByCustomerItems.push({
                name: el.communityName,
                value: el.communityName
              });
            });

            self.customers = items;
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.loadingCustomers = false;
        });
    },

    deleteRequest(item) {
      let self = this;
      if (!item) return;

      this.$store
        .dispatch(DELETE, {
          listName: `${API_SERVICE_REQUESTS}`,
          id: item.id
        })
        .then(function() {
          item.deleteDialog = false;

          self.getRequests();
        });
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
    },

    async getExportFile() {
      // this.isLoading = true;

      let url = `${process.env.VUE_APP_API_URL}${API_SERVICE_REQUESTS}/User/`;
      if (this.viewByAssignedCrewLeader == "me")
        url += this.userInfo.aspireId + "/export";
      else url += this.viewByAssignedCrewLeader + "/export";

      url += `?mediumFormat=${true}&branch=${
        this.viewByBranch
      }&accountManager=${this.viewByAccountManager}&customer=${
        this.viewByCustomer
      }&uid=${this.userInfo.id}&userRole=${this.userInfo.role}`;

      window.open(url, "_blank");

      // let self = this;

      // await this.$store
      //   .dispatch(GET_PAGED, {
      //     listName: url,
      //     params: {
      //       mediumFormat: true,
      //       branch: this.viewByBranch,
      //       accountManager: this.viewByAccountManager,
      //       customer: this.viewByCustomer,
      //       uid: this.userInfo.id,
      //       userRole: this.userInfo.role
      //     }
      //   })
      //   .then(response => {
      //     if (response) self.isLoading = false;
      //   });
    },
    async getRequests() {
      if (this.isLoading) return;

      this.isLoading = true;

      let url = `${API_SERVICE_REQUESTS}/User/`;
      if (this.viewByAssignedCrewLeader == "me") url += "0";
      else url += this.viewByAssignedCrewLeader;

      let self = this;

      await this.$store
        .dispatch(GET_PAGED, {
          listName: url,
          params: {
            mediumFormat: true,
            branch: this.viewByBranch,
            accountManager: this.viewByAccountManager,
            customer: this.viewByCustomer
          }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            const { sortBy, sortDesc } = self.options;

            let items = response.data.$values;

            // request type
            // essential = all except Wildlife Damage, Mulch Request, Request For Proposal
            // proposals = Request For Proposal
            // wildlife = Wildlife Damage
            // mulch = Mulch Request

            let requestsItems = [];
            let requestForProposalItems = [];
            let wildlifeDamageItems = [];
            let mulchRequestItems = [];
            let typesNotToInclude = [
              "Wildlife Damage",
              "Mulch Request",
              "Request For Proposal"
            ];
            requestsItems = items.filter(
              el => !typesNotToInclude.includes(el.requestType?.name)
            );
            this.requestsCount = requestsItems.length;

            requestForProposalItems = items.filter(
              el => el.requestType?.name == "Request For Proposal"
            );
            this.requestForProposalCount = requestForProposalItems.length;

            wildlifeDamageItems = items.filter(
              el => el.requestType?.name == "Wildlife Damage"
            );
            this.wildlifeDamageCount = wildlifeDamageItems.length;

            mulchRequestItems = items.filter(
              el => el.requestType?.name == "Mulch Request"
            );
            this.mulchRequestCount = mulchRequestItems.length;

            if (this.requestTypeToShow == "essential") {
              items = requestsItems;
            } else if (this.requestTypeToShow == "Request For Proposal") {
              items = requestForProposalItems;
            } else if (this.requestTypeToShow == "Wildlife Damage") {
              items = wildlifeDamageItems;
            } else if (this.requestTypeToShow == "Mulch Request") {
              items = mulchRequestItems;
            }

            // if (self.viewByBranch && self.viewByBranch !== "all") {
            //   let viewByBranch = self.viewByBranch;
            //   items = items.filter(el => el.branchName === viewByBranch);
            // }

            // if (
            //   self.viewByAccountManager &&
            //   self.viewByAccountManager !== "all"
            // ) {
            //   let viewByAccountManager = self.viewByAccountManager;
            //   items = items.filter(
            //     el => el.accountManagerName === viewByAccountManager
            //   );
            // }

            // if (self.viewByCustomer && self.viewByCustomer !== "all") {
            //   let viewByCustomer = self.viewByCustomer;
            //   items = items.filter(el => el.communityName === viewByCustomer);
            // }

            let newItems = [];
            let dueItems = [];
            let openItems = [];
            let inProcessItems = [];
            let onHoldItems = [];
            let closedItems = [];

            let endWeekDate = moment
              .utc()
              .endOf("isoWeek")
              .toDate();

            dueItems = items.filter(
              el =>
                moment.utc(el.targetCompletionDate).toDate() < endWeekDate &&
                !el.isCompleted &&
                !el.isNew &&
                !el.isOnHold
            );
            this.dueCount = dueItems.length;
            closedItems = items.filter(el => el.isCompleted);
            this.closedCount = closedItems.length;
            inProcessItems = items.filter(el => el.isInProcess);
            this.inProcessCount = inProcessItems.length;
            onHoldItems = items.filter(el => el.isOnHold);
            this.onHoldCount = onHoldItems.length;
            newItems = items.filter(el => el.isNew);
            this.newCount = newItems.length;
            openItems = items.filter(el => el.isOpen);
            this.openCount = openItems.length;

            if (this.tab === "tab-2") {
              // due this week
              items = dueItems;
            } else if (this.tab === "tab-6") {
              // closed
              items = closedItems;
            } else if (this.tab === "tab-5") {
              // on hold
              items = onHoldItems;
            } else if (this.tab === "tab-4") {
              // in process
              items = inProcessItems;
            } else if (this.tab === "tab-1") {
              // new
              items = newItems;
            } else {
              // all open
              items = openItems;
            }

            // if (self.tab === "tab-2") {
            //   let endWeekDate = moment()
            //     .endOf("isoWeek")
            //     .toDate();

            //   items = items.filter(
            //     el =>
            //       moment(el.targetCompletionDate).toDate() < endWeekDate &&
            //       !el.isCompleted
            //   );
            // } else if (self.tab === "tab-5") {
            //   items = items.filter(el => el.isCompleted);
            // } else if (this.tab === "tab-4") {
            //   items = items.filter(el => el.isInProcess);
            // } else if (this.tab === "tab-1") {
            //   items = items.filter(el => el.isNew);
            // } else {
            //   items = items.filter(el => el.isOpen || el.isInProcess);
            // }

            items.forEach(function(element) {
              element.deleteDialog = false;
              element.assignedToFullName = element.assignedTo?.fullName;
              // element.priorityId = element.priority.id;
              element.requestTypeName = element.requestType?.name;
              if (element.location)
                element.streetLocation = element.location
                  .replace(/\b\d+/i, "")
                  .trimStart();
              else element.streetLocation = "";

              //   let comments = element.comments.$values;
              //   if (comments.length > 0) {
              //     let lastComment = comments.sort((a, b) => {
              //       return new Date(b.created) - new Date(a.created);
              //     })[0];
              //     if (lastComment.assignedTo) {
              //       element.assignedTo = lastComment.assignedTo;
              //       element.assignedToFullName = element.assignedTo?.fullName;
              //     }

              //     element.requestType = lastComment.requestType;
              //     element.requestTypeId = lastComment.requestType.id;
              //     // if (lastComment.priority) {
              //     //   element.priority = lastComment.priority;
              //     //   element.priorityId = element.priority.id;
              //     // }
              //   }
            });

            // if (self.viewByPriority && self.viewByPriority !== "all") {
            //   let viewByPriority = self.viewByPriority;
            //   items = items.filter(el => el.priority.name === viewByPriority);
            // }

            const total = items.length;

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              });
            }

            // if (itemsPerPage > 0) {
            //   items = items.slice(
            //     (page - 1) * itemsPerPage,
            //     page * itemsPerPage
            //   );
            // }
            self.records = items;
            self.totalRecords = total;
          } else {
            self.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          self.isLoading = false;
        });
    }
  },
  computed: {
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    },
    isCrewLeaderOrAccountManager() {
      return (
        this.userInfo?.profile?.aspireRole == "Crew Leader" ||
        this.userInfo?.profile?.aspireRole == "Account Manager"
      );
    }
  }
};
</script>
<style scoped>
.active-request-type-link {
  color: #df6526 !important;
}
</style>
